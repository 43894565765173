var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"demo-spin-article"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label col-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('payRollCycle.cycleYear')))]),_c('div',[_c('date-Picker',{class:{
                        'is-invalid': _vm.errors.has('cycle_year')
                    },staticStyle:{"width":"487px"},attrs:{"value":_vm.model.cycle_year,"type":"year","format":"yyyy","placeholder":"Select year"},on:{"on-change":_vm.cycleYearChange}}),(_vm.errors.has('cycle_year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_year'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label col-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('payRollCycle.cycleMonth')))]),_c('div',[_c('date-Picker',{class:{
                        'is-invalid': _vm.errors.has('cycle_month')
                    },staticStyle:{"width":"487px"},attrs:{"value":_vm.model.cycle_month,"type":"month","format":"MM","placeholder":"Select month"},on:{"on-change":_vm.cycleMonthChange}}),(_vm.errors.has('cycle_month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_month'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label col-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('payRollCycle.status')))]),_c('div',[_c('Select',{class:{
                    'is-invalid': _vm.errors.has('cycle_status')
                },model:{value:(_vm.model.cycle_status),callback:function ($$v) {_vm.$set(_vm.model, "cycle_status", $$v)},expression:"model.cycle_status"}},_vm._l((_vm.payRollCycle),function(payRollCycle,index){return _c('Option',{key:index,attrs:{"value":payRollCycle.value}},[_vm._v(_vm._s(payRollCycle.label))])}),1),(_vm.errors.has('cycle_status'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_status'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-label required"},[_vm._v(_vm._s(_vm.$t('payRollCycle.salaryTaxFxRate')))]),_c('div',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('Input',{ref:"salary_tax_fx_rate",class:{
                            'is-invalid': _vm.errors.has('salary_tax_fx_rate')
                        },staticStyle:{"width":"270%"},attrs:{"type":"number","placeholder":_vm.$t('payRollCycle.enterNumber')},model:{value:(_vm.model.salary_tax_fx_rate),callback:function ($$v) {_vm.$set(_vm.model, "salary_tax_fx_rate", _vm._n($$v))},expression:"model.salary_tax_fx_rate"}}),(_vm.errors.has('salary_tax_fx_rate'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('salary_tax_fx_rate'))+" ")]):_vm._e(),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.salary_tax_fx_rate ? _vm.formatNumber(_vm.model.salary_tax_fx_rate) : _vm.$t('payRollCycle.enterNumber'))+" ")])],1)],1)]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label col-label required"},[_vm._v(_vm._s(_vm.$t('payRollCycle.nssfFxRate')))]),_c('div',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.nssf_fx_rate),expression:"model.nssf_fx_rate"}],ref:"nssf_fx_rate",staticClass:"form-control",class:{
                            'is-invalid': _vm.errors.has('nssf_fx_rate')
                        },staticStyle:{"width":"276%"},attrs:{"type":"number","placeholder":_vm.$t('payRollCycle.enterNumber')},domProps:{"value":(_vm.model.nssf_fx_rate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "nssf_fx_rate", $event.target.value)}}}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.nssf_fx_rate ? _vm.formatNumber(_vm.model.nssf_fx_rate) : _vm.$t('payRollCycle.enterNumber'))+" ")]),(_vm.errors.has('nssf_fx_rate'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('nssf_fx_rate'))+" ")]):_vm._e()])],1)]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel')))]),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","outline":"","waiting":_vm.waiting_new,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('saveAddNew')))]):_vm._e(),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('save')))]):_vm._e(),(_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('update')))]):_vm._e()],1)])]),(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }