<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <label class="form-label col-label required tw-text-xs">{{
                    $t('payRollCycle.cycleYear')
                }}</label>
                <div>
                    <date-Picker :value="model.cycle_year" type="year" format="yyyy" placeholder="Select year"
                        style="width: 487px" @on-change="cycleYearChange" :class="{
                            'is-invalid': errors.has('cycle_year')
                        }"></date-Picker>
                    <div class="invalid-feedback" v-if="errors.has('cycle_year')">
                        {{ errors.first('cycle_year') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label col-label required tw-text-xs">{{
                    $t('payRollCycle.cycleMonth')
                }}</label>
                <div>
                    <date-Picker :value="model.cycle_month" type="month" format="MM" placeholder="Select month"
                        style="width: 487px" @on-change="cycleMonthChange" :class="{
                            'is-invalid': errors.has('cycle_month')
                        }"></date-Picker>
                    <div class="invalid-feedback" v-if="errors.has('cycle_month')">
                        {{ errors.first('cycle_month') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label col-label required tw-text-xs">{{
                    $t('payRollCycle.status')
                }}</label>
                <div>
                    <Select v-model="model.cycle_status" :class="{
                        'is-invalid': errors.has('cycle_status')
                    }">
                        <Option v-for="(payRollCycle, index) in payRollCycle" :value="payRollCycle.value" :key="index">{{
                            payRollCycle.label }}</Option>
                    </Select>
                    <div class="invalid-feedback" v-if="errors.has('cycle_status')">
                        {{ errors.first('cycle_status') }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="form-label col-label required">{{
                    $t('payRollCycle.salaryTaxFxRate')
                }}</label>
                <div>
                    <Poptip trigger="focus">
                        <Input v-model.number="model.salary_tax_fx_rate" type="number"
                            :placeholder="$t('payRollCycle.enterNumber')" style="width: 270%" ref="salary_tax_fx_rate"
                            :class="{
                                'is-invalid': errors.has('salary_tax_fx_rate')
                            }">
                        </Input>
                        <div class="invalid-feedback" v-if="errors.has('salary_tax_fx_rate')">
                            {{ errors.first('salary_tax_fx_rate') }}
                        </div>
                        <div slot="content">
                            {{
                                model.salary_tax_fx_rate
                                ? formatNumber(model.salary_tax_fx_rate)
                                : $t('payRollCycle.enterNumber')
                            }}
                        </div>
                    </Poptip>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label col-label required">{{
                    $t('payRollCycle.nssfFxRate')
                }}</label>
                <div>
                    <Poptip trigger="focus">
                        <input style="width: 276%" type="number" class="form-control" ref="nssf_fx_rate"
                            :placeholder="$t('payRollCycle.enterNumber')" v-model="model.nssf_fx_rate" :class="{
                                'is-invalid': errors.has('nssf_fx_rate')
                            }" />
                        <div slot="content">
                            {{
                                model.nssf_fx_rate
                                ? formatNumber(model.nssf_fx_rate)
                                : $t('payRollCycle.enterNumber')
                            }}
                        </div>
                        <div class="invalid-feedback" v-if="errors.has('nssf_fx_rate')">
                            {{ errors.first('nssf_fx_rate') }}
                        </div>
                    </Poptip>
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button @click.prevent="() => $emit('cancel')" class="btn-gray">
                        {{ $t('cancel') }}</ts-button>
                    <ts-button v-if="!isUpdate" color="primary" outline :waiting="waiting_new"
                        :disabled="waiting_new || waiting" @click.prevent="onSaveAddNew">
                        {{ $t('saveAddNew') }}</ts-button>
                    <ts-button color="primary" v-if="!isUpdate" :waiting="waiting" :disabled="waiting_new || waiting"
                        @click.prevent="onSave">
                        {{ $t('save') }}</ts-button>
                    <ts-button v-if="isUpdate" color="primary" @click.prevent="onUpdate" :waiting="waiting">
                        {{ $t('update') }}</ts-button>
                </div>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapState, mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    nama: 'payroll-cycle-form',

    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            payRollCycle: [
                {
                    value: 'Select',
                    label: 'Select'
                },
                {
                    value: 'Active',
                    label: 'Active'
                },
                {
                    value: 'Revise',
                    label: 'Revise'
                },
                {
                    value: 'Closed',
                    label: 'Closed'
                }
            ],
            model: {
                cycle_year: '',
                cycle_month: '',
                cycle_status: null,
                salary_tax_fx_rate: null,
                nssf_fx_rate: null
            }
        }
    },
    computed: {
        ...mapState('humanResource/payrollCycle', ['edit_data']),
        ...mapGetters(['formatNumber']),

        isUpdate() {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        ...mapActions('humanResource/payrollCycle', ['find']),
        async fetchResource() {
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: 'error', text: error.message })
            })
            this.setEditData()
        },
        async onSave() {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('humanResource/payrollCycle/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew() {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('humanResource/payrollCycle/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onUpdate() {
            this.waiting = true
            this.$store
                .dispatch('humanResource/payrollCycle/update', {
                    id: this.edit_data.payroll_cycle_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.cycle_year = this.edit_data.cycle_year
                this.model.cycle_month = this.edit_data.cycle_month
                this.model.cycle_status = this.edit_data.cycle_status
                this.model.salary_tax_fx_rate =
                    this.edit_data.salary_tax_fx_rate
                this.model.nssf_fx_rate = this.edit_data.nssf_fx_rate
            }
        },
        clearInput() {
            this.errors = new Errors()
            this.model.cycle_year = null
            this.model.cycle_month = null
            this.model.cycle_status = null
            this.model.salary_tax_fx_rate = null
            this.model.nssf_fx_rate = null
        },
        cycleYearChange(value) {
            this.model.cycle_year = value
        },
        cycleMonthChange(value) {
            this.model.cycle_month = value
        },
        notice(not) {
            this.$Notice[not.type]({
                title: 'PAYROLL CYCLE',
                desc: not.text
            })
        }
    }
}
</script>
